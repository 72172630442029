body {
  overflow-x: hidden;
}
.pointer {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.referencemodal {
  > div > div {
    background: none;
    border: 0px;

    > div {
      &:first-child {
        border: 0px;
        margin-bottom: -10px;
        button {
          background: white;
          margin-right: -40px;
          padding: 5px 10px;
          opacity: 1;
          border-radius: 50px 50px 50px 0px;
        }
      }
      &:last-child {
        height: 39.8vw;
        background: white;
        padding: 0px;
        h4 {
          position: relative;
          margin-left: 0px;
          margin-top: -15px;
          top: 0px;
          z-index: 99999;
          padding: 5px;
          background: #ffffff00;
          font-size: 10px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.hall-navigator {
  margin: 0px;
  padding: 0px !important;
  div {
    border-radius: 0px !important;
    border: 0px;
  }
  > div {
    height: calc(100vh - 60px);
    width: 98.5vw;
    max-width: 98.5vw;
    margin: 5px auto;
    > div > div {
      &:first-child {
        margin-bottom: 0px;
        background: white;
        button {
          background: white;
          height: 60px;
          margin-right: 0px;
          opacity: 1;
        }
      }
      &:last-child {
        border-top: 1px solid black;
        height: calc(100vh - 80px);
        padding: 0px;
        overflow-y: scroll;
        iframe {
          border: none !important;
        }
      }
    }
  }
}

.btn-back {
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 1;
}

.idiomas {
  transition: all 0s ease-in-out;
  position: absolute;
  z-index: 100;
  width: 140px;
  height: 40px;

  strong {
    display: block;
    font-size: 10px;
  }

  div {
    img {
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.btn-modal-back {
  background: white;
  height: auto !important;
  padding: 0px !important;
  margin-right: 10px !important;
  padding-right: 10px !important;
  opacity: 1 !important;
  border: 0px;
  border-right: 1px solid grey;
}

.odd-padro {
  width: 75vw;
  display: block;
}
.odd-patro-image {
  width: 75vw;
  display: block;
  position: absolute;
}
.patroside {
  width: 20vw;
  padding: 0px 1%;
}

.modal-open .modal {
  padding-left: 0 !important;	
}

.image-mapper-container {
  position: relative;
}

.image-mapper-tooltip {
  position: absolute !important;
  color: #fff;
  padding: 10px;
  background: rgba(0,0,0,0.8);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000 !important;
}