@media only screen and (orientation: landscape) and (max-height: 375px) {
  .slick-list {
    .slick-track {
      .slick-slide {
        .slick-slide-a {
          margin-top: -6vh !important;
          height: 332px !important;
          padding: 111% 30% 30% 34%;
          font-size: 0.7em;
        }
      }
    }
  }

  .modal-or {
    margin-top: 30px !important;
    margin-left: 10px !important;

    .orientation-modal-text {
      margin-left: 7% !important;
      margin-top: 30px !important;
      width: calc(38% - -27vw) !important;
    }

    .carousel-item {
      img {
        width: 81% !important;
        height: 100% !important;
      }
    }
  }

  .modal-ep {
    .slick-list {
      .slick-track {
        .slick-slide {
          .slick-slide-a {
            margin-top: 2vh !important;
            height: 341px !important;
            padding: 111% 30% 30% 34%;
            font-size: 0.7em;
          }
        }
      }
    }
  }

  .modal-play {
    top: 5px !important;
    .modal-dialog {
      max-width: 85% !important;
      .carousel-item {
        img {
          width: 55%;
        }
      }
    }
  }

  .modal-sp {
  }
}

@media only screen and (max-width: 991px) {
  .navmenu {
    .centering-menu {
      box-shadow: #00000075 -4px 2px 15px;
      position: fixed;
      margin-left: 0px !important;
      left: 0px !important;
      top: 0px;
      bottom: 0px;
      background: #ffffff;
      border-radius: 0px !important;
      margin-top: 0px;
      padding: 10px;
      border-radius: 0px 0px 5px 5px;
    }
  }
}

@media only screen and (max-height: 411px) {
  .navmenu {
    padding: 0px;
    position: fixed;
  }
  .navbar-toggler {
    font-size: 12px !important;
    margin: 0px 10px 10px 169px !important;
    z-index: 555;

    border-radius: 0px;
    background-color: white !important;

    &.collapsed,
    &.collapsin {
      margin: 10px !important;
    }
  }

  .modal-ep {
    max-width: 95% !important;
    left: 3.5%;
    .modal-dialog {
      max-width: 94% !important;
      margin: 10px 10px 10px 10px !important;
    }
  }

  .modal-pc {
    padding-top: 0px !important;
    margin-top: 0px !important;

    .tablet {
      padding: 80px 25px 200px 25px;
    }

    .closetablet {
      top: 20px;
      button {
        background: white;
        padding: 10px 15px !important;
        border-radius: 50% !important;
        opacity: 0.2 !important;
      }
    }

    .btn-secondary,
    .btn-danger {
      left: -40px !important;
      width: 130% !important;
    }

    .clock {
      right: -93px !important;
    }

    .programmingline {
      margin: 0px !important;
      .col-md-1 {
        padding: 0px !important;
        width: 10px !important;
        flex: 0 0 4%;
      }

      .col-md-2 {
        padding-right: 0px;
        h5 {
          font-size: 14px !important;
        }
        p {
          font-size: 14px !important;
          margin: 8px 0px !important;
        }
        button {
          padding: 4px 8px;
          font-size: 14px !important;
        }
      }
      .col-md-9 {
        padding-left: 0px;
      }
    }
  }

  .nomarginslide {
    height: calc(100vh - 47px) !important;
  }

  .slick-slider {
    .slick-prev {
      z-index: 20 !important;
      left: calc(50% - 100px) !important;
    }

    .slick-next {
      right: calc(50% - 110px) !important;
    }

    .slick-list {
      .slick-track {
        .slick-slide {
          .slick-slide-a {
            margin-top: 1vh !important;
            height: 366px !important;
            padding: 111% 30% 30% 34%;
            font-size: 0.7em;
          }
        }
      }
    }
  }

  .btn-back {
    position: absolute;
    bottom: 0% !important;
    left: 10px !important;
    z-index: 1 !important;

    button {
      font-size: 12px;
    }
  }

  .logo-evento {
    top: 10vh !important;
  }

  .display {
    .chat {
      display: none !important;
    }
  }
  .closetablet {
    border: red solid 1px;
  }
}

.lockMobile {
  background-color: white;
  position: fixed;
  top: 0%;
  bottom: 0%;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .logo {
  }

  .celular {
    width: 40%;
    margin-top: 30px;
  }

  section {
    padding: 60px 50px;
    text-align: center;
  }
}

@media only screen and (orientation: landscape) and (max-height: 414px) {
  .display {
    .chat {
      display: none !important;
    }
  }

  .closetablet {
    border: red solid 1px;
  }
}

@media only screen and (orientation: landscape) and (max-height: 411px) {
  .modal-or {
    margin-top: 45px !important;
    margin-left: 8px !important;

    .orientation-modal-text {
      margin-left: 7% !important;
      margin-top: 30px !important;
      width: calc(38% - -27vw) !important;
    }

    .carousel-item {
      img {
        width: 81% !important;
        height: 100% !important;
      }
    }
  }

  .main-speaker {
    .row {
      margin-left: 25px;
      .col-md-4 {
        max-width: 30% !important;
        figure {
          width: 140px !important;
          height: 140px !important;
        }
        h2 {
          font-size: 15px !important;
        }
      }
    }
  }
}

.user-mobile {
  position: fixed;
  right: 1.5%;
  top: 10px;

  .avatar-login {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0px;
    }

    a {
      padding: 0px !important;
      font-size: 11px;
      margin-left: 7px;
      color: black;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 731px) {
  .jvJgJU {
    button {
      boder: red solid 1px;
      margin-top: 4px;
      margin-left: 12px;
      padding: 2px !important;
    }
    .clock {
      right: -71px;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 823px) {
  body,
  html {
    overflow: hidden !important;
    position: relative;
    overflow-x: hidden;
  }

  html,
  body {
    position: relative;
    overflow-x: hidden;
  }

  .mobile-drop {
    position: fixed;
    background-color: #00000058;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100vh;
    z-index: 1500;
  }

  .close {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    background-color: black !important;
    border-radius: 100%;
    width: 30px;
    z-index: 5000;
    height: 30px;
    position: absolute;
    right: 0.5%;
    top: 5px;
  }

  .mobile {
    top: 10px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 10px !important;

    padding: 20px 10px 40px 10px !important;
    border: #000000dd solid thin;
    overflow: hidden;
    border-radius: 1em;
    position: fixed;
    z-index: 10000;
    background-color: #fff;

    .jvJgJU {
      button {
        margin-top: 4px;
        margin-left: 12px;
        padding: 2px !important;
      }
      .clock {
        right: -71px;
      }
    }

    #scientific_programming {
      padding: 0px;
      max-width: 100% !important;
      height: 100vh;
    }

    .rooms {
      font-size: 0.8em;
    }

    .btncontainer {
      .btn {
        h2 {
          font-size: 13px !important;
          font-weight: 400 !important;
        }
        padding: 5px 20px;
      }
    }

    .ikKOYe {
      .clock {
        right: -40px;
      }
    }

    .programmingcontainer {
      padding: 15px;
      .programmingline {
        margin: 0px !important;
        padding: 15px 0px 0px 0px !important;
      }
      .col-md-2 {
        width: 150px !important;
      }
      .col-md-1 {
        flex: 0;
      }
      .col-md-9 {
        flex: 1;
      }
    }

    .filtros {
      .row {
        .col-md-1 {
        }

        .col-md-4 {
        }

        .col-md-4 {
        }
      }
    }
  }
}
.whatsapp img {
  width: 31px;
  position: fixed;
  top: 10px;
  left: 59px;
}
