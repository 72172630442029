.container {
  height: 100%;
  align-content: center;
}

.uploadContainer {
  position: relative;
  overflow: hidden;
}

.image_outer_container {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  position: relative;
}
.margin-top-15 {
  margin-top: -15px;
}
.image_inner_container {
  border-radius: 50%;
  padding: 1.2px;
  background: #4ea8b2;
  //background: -webkit-linear-gradient(to bottom, #fcb045, #fd1d1d, #833ab4);
  //background: linear-gradient(to bottom, #fcb045, #fd1d1d, #833ab4);
}
.image_inner_container img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 5px solid white;
}
.navmenu {
  transition: all 0.5s ease-in-out;
  width: 100%;
  padding: 0px 25px;
  margin: 0px;

  .centering-menu {
    position: relative;
    margin-left: -280px;
    left: 50%;
    background: #ffffffdd;
    margin-top: 0px;
    padding: 5px;
    padding-right: 20px;
    border-radius: 0px 0px 5px 5px;
  }
  .navbar-toggler {
    background: #ffffffdd;
    border: 0px;
  }
  a {
    font-variant: all-small-caps;
    font-size: 14px;
    color: #555555;
    border: 1px;
    border-radius: 10px;
    padding: 1px;
    margin: 1px;
  }
}
.avatar-login {
  border: 0px solid grey;
  background: none;
  width: 25px;
  height: 25px;
  > img {
    margin-top: 14px;
    margin-left: 5px;
    border: 1px solid grey;
    width: 25px;
    height: 25px;
    &:hover {
      border: 1px solid black;
    }
  }
}

.hidden {
  transform: translateY(-105%);
  transition-delay: 1s;
}

.modalBody {
  display: flex;
  justify-content: center;

  img {
  }

  .row-gamification {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #c8c8c8;

    .text-description {
      color: #555555;
    }

    p,
    h5 {
      margin-bottom: 0;
    }
  }

  .img-person {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-right: 40px;

    display: block;
  }

  .overlay {
    position: absolute;
    top: 25px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    width: 100px;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #fffffff0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    .overlay-icon {
      font-size: 40px;
      color: #545454;
    }
  }
}
